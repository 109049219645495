<template>
  <div>
    <Table
        :headings="tableHeadings"
        :data="articles"
        :loading="isLoading"
        @actionView="actionView"
        @actionDelete="actionDelete"
    >
    </Table>
    <v-pagination
        v-model="pageNumber"
        :pages="meta.last_page"
        @update:modelValue="updateHandler"
    >
    </v-pagination>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Table from '@/components/table/Table.vue';
import { Paginator } from "@/types/Paginator";
import { spinnerIsLoading } from "@/mixins/spinner.mixin";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import "@/assets/css/paginator.css";
import { mapGetters } from "vuex";
import { deleteArticle, paginateArticles } from "@/api/news.api";
import { sweetAlertCallback, sweetAlertSuccess } from "@/utilities/sweetalert";

export default defineComponent({
  name: "NewsList",
  mixins: [spinnerIsLoading],
  components: {
    Table,
    VPagination,
  },
  data() {
    return {
      tableHeadings: {
        id: '#',
        title: 'Titolo',
        created_at: 'Creato il'
      },
      meta: {} as Paginator,
      articles: [] as any[],
      pageNumber: 1 as number,
    }
  },
  methods: {
    async getArticles(pageNumber: number) {
      this.startLoading();

      const response: any = await paginateArticles(pageNumber);

      this.articles = response.data;
      this.meta = response.meta;

      this.stopLoading();
    },
    updateHandler(page: number) {
      this.pageNumber = page;

      this.getArticles(page);
    },
    actionView(id: number) {
      this.$router.push({ name: 'HandleSpecificNews', params: { id } })
    },
    async actionDelete(data: any) {
      const id = data.id;

      sweetAlertCallback('Sei sicuro di voler eliminare definitivamente l\'articolo?')
          .then(async (result) => {
            if (!result.isConfirmed) return;

            const response = await deleteArticle(id);

            if (!response) return;

            const index = this.articles.findIndex((articles: any) => articles.id === id);

            if (index <= -1) return;

            this.articles.splice(index, 1);

            return sweetAlertSuccess('success','Articolo eliminato con successo');
          });
    }
  },
  computed: {
    ...mapGetters([
      'user',
    ])
  },
  mounted() {
    if (!this.user.is_admin) {
      return this.$router.push({ name: 'Dashboard' });
    }

    this.getArticles(this.pageNumber);
  }
})
</script>

<style scoped>

</style>